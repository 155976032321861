import React from "react"
import {Link} from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/SEO"
import Button from "../components/Button"

const NotFoundPage = ({ location }) => (
  <Layout location={location}>
    <SEO title="404: Not found" />
    <h1>NOT FOUND</h1>
    <p>Sorry, the requested URL can't be found</p>
    <Link to="/">
      <Button btnStyle="primary" customClass="c3-primary" icon="home" iconPos="left" >
        Homepage
      </Button>
    </Link>
  </Layout>
)

export default NotFoundPage
